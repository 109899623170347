import Photo1 from "../../assets/photos/1.jpg";
import Photo2 from "../../assets/photos/2.jpg";
import Photo3 from "../../assets/photos/3.jpg";
import Photo4 from "../../assets/photos/4.jpg";
import Photo5 from "../../assets/photos/5.jpg";
import Photo6 from "../../assets/photos/6.jpg";
import Photo7 from "../../assets/photos/7.jpg";
import Photo8 from "../../assets/photos/8.jpg";
import Photo9 from "../../assets/photos/9.jpg";

import SchoolPhoto1 from "../../assets/school-photos/1.jpg";
import SchoolPhoto2 from "../../assets/school-photos/2.jpg";
import SchoolPhoto3 from "../../assets/school-photos/3.jpg";
import SchoolPhoto4 from "../../assets/school-photos/4.jpg";
import SchoolPhoto5 from "../../assets/school-photos/5.jpg";

export default {
  Photo1,
  Photo2,
  Photo3,
  Photo4,
  Photo5,
  Photo6,
  Photo7,
  Photo8,
  Photo9,
};

const SchoolPhotos = {
  SchoolPhoto1,
  SchoolPhoto2,
  SchoolPhoto3,
  SchoolPhoto4,
  SchoolPhoto5,
};

export { SchoolPhotos };
