import React from "react";
import { Context as NavigationContext } from "../../provider/navigation";
import { Context as ContentContext } from "../../provider/content";
import { LinkButton } from "../../components/button";
import Icons from "../../components/icons";
import FeedbacksPhoto from "../../assets/backgrounds/feedbacks.jpg";

import "./feedbacks.scss";

const Feedback = (review) => {
  return (
    <div className="feedbacks-list-feedback" key={review.id}>
      <div
        className="photo"
        style={{
          "background-image": `url(https://api.vera.school/${review.photo_url})`,
        }}
      ></div>
      <div className="text">
        <div className="title">
          <h3>{review.name}</h3>
          <h4>{review.address}</h4>
        </div>
        <div className="description">
          <p>{review.lead}</p>
        </div>
      </div>
    </div>
  );
};

const FeedbacksPage = () => {
  const { setBackground, setTheme } = React.useContext(NavigationContext);
  const { reviews, settings: { social = {} } = {} } =
    React.useContext(ContentContext);

  setTheme("transparent");
  setBackground(FeedbacksPhoto);

  return (
    <div className="feedbacks">
      <div className="row title">
        <h1>Отзывы</h1>
        <p>
          Со всеми отзывами вы можете ознакомиться в любой социальной сети по
          хэштегу <span>#школамолодоговрача</span>
        </p>

        <div className="social">
          <a
            href="https://vk.com/feed?q=%23verahivmedschool&section=search"
            target="_blank"
          >
            <img src={Icons.VKIcon} alt="VK Icon" />
          </a>
        </div>
      </div>

      <div className="row feedbacks-list">
        {reviews &&
          reviews.map((review) => {
            return <Feedback key={review.id} {...review} />;
          })}
      </div>

      {/* enable when open
            <div className="row participants-registration">
        <LinkButton to="/registration">Подать заявку на участие</LinkButton>
      </div>
      */}
    </div>
  );
};

export default FeedbacksPage;
